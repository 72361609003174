<template>
  <span>
  <template v-if="row.iacctpaytype===10">
    {{
      {10: '待支付', 20: '支付中', 30: '支付完成', 40: '支付失败', 99: '作废'}[row.ipaystatus]||'未知'
    }}{{row.bispaid?'':'(在途)'}}
  </template>
  <template v-else>
    {{
      {10: '待支付', 20: '支付中', 30: '支付完成', 40: '支付失败', 99: '作废'}[row.ipaystatus]||'未知'
    }}
  </template>
    </span>
</template>
<script>
import Template from '@views/template.vue'

export default {
  name: 'GGPayStatus',
  components: {},
  props: [ 'row' ],
  computed: {

  },
  methods: {
    label () {

    }
  },
  mounted () {
  }
}
</script>
<style scoped>
</style>
