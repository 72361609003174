<template>
    <transition :name="transitionName">
        <div class="compareIcon" v-if="showCompareIncon" @click="showCompareList(true)">
          <svg aria-hidden="true" class="sidebar-icon" width="24" height="24" version="1.1" viewBox="0 0 16 16"><path d="M8.625 1a7.584 7.584 0 0 0-4.147 1.236.557.557 0 0 0-.385.519c0 .309.263.559.588.559a.597.597 0 0 0 .363-.13l.01.012a6.389 6.389 0 0 1 3.571-1.079c3.424 0 6.199 2.634 6.199 5.884s-2.775 5.884-6.199 5.884a6.383 6.383 0 0 1-3.807-1.246l-.043.067a.594.594 0 0 0-.304-.092c-.325 0-.588.25-.588.558 0 .288.232.516.526.547A7.578 7.578 0 0 0 8.625 15C12.698 15 16 11.866 16 8s-3.302-7-7.375-7z" stroke="none"></path><path class="svg-fill-text" stroke="none" d="M5.966 5.074h-.944v1.078h-1.9v.827h1.9v2.902c0 .271-.155.407-.465.407-.336 0-.674-.013-1.013-.038.102.339.174.648.218.928.693 0 1.161-.008 1.405-.023s.438-.104.582-.265.216-.396.216-.708V6.979h.607v-.827h-.606V5.074zM2.964 6.359v-.808H.101v.821H2.04a6.577 6.577 0 0 1-.353 1.51L.813 6.616l-.718.433 1.158 1.752a5.38 5.38 0 0 1-1.257 1.444c.198.313.361.594.488.84a6.699 6.699 0 0 0 1.299-1.467l.646 1.01.818-.508-.972-1.394a8.829 8.829 0 0 0 .689-2.367zm.996 2.996l.785-.489a30.974 30.974 0 0 0-1.187-1.523l-.686.439c.396.514.758 1.038 1.088 1.573zm8.184.718c-.056.114-.169.172-.34.172h-.462c-.225 0-.336-.132-.336-.395V8.5c.785-.6 1.55-1.238 2.295-1.915l-.857-.64a22.359 22.359 0 0 1-1.438 1.436V5.068h-.977v5.083c0 .639.316.959.949.959h.832c.262 0 .482-.031.664-.093s.318-.148.411-.26c.093-.112.174-.314.244-.608.07-.293.128-.638.171-1.032a14.773 14.773 0 0 1-.982-.32c-.061.735-.12 1.161-.174 1.276zm-3.988-.048V7.801h1.51v-.865h-1.51V5.144H7.18v4.889c0 .255-.077.438-.231.552l.475.739c.545-.28 1.306-.563 2.283-.847a9.455 9.455 0 0 1-.021-.947l-1.53.495z"></path></svg>
        </div>
    </transition>
</template>

<script>
export default {
  name: 'Compare',
  props: {
    transitionName: {
      type: String,
      default: 'fade'
    }
  },
  data () {
    return {
      showCompareIncon: false
    }
  },
  watch: {
    $route (to) {
      if (to.path === '/goods/compare') {
        this.showCompareIncon = false
      } else {
        if (localStorage.getItem('COMPARE_GOODS') && JSON.parse(localStorage.getItem('COMPARE_GOODS')).length > 0) {
          this.showCompareIncon = true
        }
      }
    }
  },
  created () {
    if (localStorage.getItem('COMPARE_GOODS') && JSON.parse(localStorage.getItem('COMPARE_GOODS')).length > 0) {
      this.showCompareIncon = true
    }
    this.$bus.$on('showCompareIcon', (status) => {
      this.showCompareIncon = status
    })
  },
  mounted () {

  },
  methods: {
    showCompareList () {
      this.$bus.$emit('showCompareList', true)
    }
  }
}
</script>

<style scoped>
    .compareIcon {
        position: fixed;
        display: inline-block;
        text-align: center;
        cursor: pointer;
    }
    .compareIcon {
        right: 20px;
        bottom: 110px;
        width: 44px;
        height: 44px;
        z-index: 100;
        border-radius: 4px;
        line-height: 50px;
        background: #b5dbff;
    }
    .compareIcon:hover {
        background: #a1d1fd;
    }

    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.5s;
    }

    .fade-enter,
    .fade-leave-to {
        opacity: 0;
    }
    .compareIcon .sidebar-icon {
      fill: #518DDD;
      stroke: #518DDD;
      margin-top: 11px;
    }

    /*.compareIcon:hover .sidebar-icon {*/
    /*  fill: #fd6500;*/
    /*  stroke: #fd6500;*/
    /*}*/
</style>
