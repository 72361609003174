import { http, ExtendHttp } from 'security-view-deal-frogsing'

/**
 * 会员用户API
 */
export default {
  forgetPasswordUrl: '/member/user/forgetPassword',
  forgetPassword (data) {
    return http.post(this.forgetPasswordUrl, data, ExtendHttp.HEADER_FORM)
  },
  sendMsgCodeUrl: '/member/user/sendMsgCode',
  sendMsgCode (data) {
    return http.get(this.sendMsgCodeUrl, data)
  },
  sendMsgCodeToMemberUrl: '/member/user/sendMsgCodeToMember',
  sendMsgCodeToMember (data) {
    return http.get(this.sendMsgCodeToMemberUrl, data)
  },

  loginUrl: '/member/user/frontLogin',
  login (data) {
    return http.post(this.loginUrl, data, ExtendHttp.HEADER_FORM)
  },
  registerUrl: '/member/user/register',
  register (data) {
    return http.post(this.registerUrl, data)
  },
  deleteUrl: '/member/user/delete/logic',
  delete (data) {
    return http.delete(this.deleteUrl, data, ExtendHttp.HEADER_FORM)
  },
  listUrl: '/member/user/findUserList',
  list (data) {
    return http.post(this.listUrl, data)
  },
  modifyPasswordUrl: '/member/user/modifyPassword',
  modifyPassword (data) {
    return http.post(this.modifyPasswordUrl, data, ExtendHttp.HEADER_FORM)
  },
  modifyMobileUrl: '/member/user/modifyMobile',
  modifyMobile (data) {
    return http.post(this.modifyMobileUrl, data, ExtendHttp.HEADER_FORM)
  },
  findSelfUrl: '/member/user/findUserInfoSelf',
  findSelf () {
    return http.post(this.findSelfUrl)
  },
  findLoginUserUrl: '/member/user/findLoginInfo',
  findLoginUser () {
    return http.post(this.findLoginUserUrl)
  },
  updateHeaderIconUrl: '/member/user/updateHeaderIcon',
  updateHeaderIcon (data) {
    return http.post(this.updateHeaderIconUrl, data, ExtendHttp.HEADER_FORM)
  },
  updateUrl: '/member/user/update',
  update (data) {
    return http.post(this.updateUrl, data)
  },
  changeStatusUrl: '/member/user/updateFrontUser',
  changeStatus (data) {
    return http.post(this.changeStatusUrl, data, ExtendHttp.HEADER_FORM)
  },
  addUrl: '/member/user/addFrontUser',
  add (data) {
    return http.post(this.addUrl, data)
  },
  validityMobileByPasswordUrl: '/member/user/checkMobileWithPwd',
  validityMobileByPassword (data) {
    return http.post(this.validityMobileByPasswordUrl, data, ExtendHttp.HEADER_FORM)
  },
  updateUserNameUrl: '/member/user/updateUserName',
  updateUserName (data) {
    return http.post(this.updateUserNameUrl, data)
  },
  updateUserAdminUrl: '/member/user/changeAdmin2User',
  updateUserAdmin (data) {
    return http.post(this.updateUserAdminUrl, data, ExtendHttp.HEADER_FORM)
  }
}
