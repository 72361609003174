<template>
     <span class="gg-padding" >{{val}}</span>
</template>

<script>
import { parseTime } from '@/util/common'

export default {
  name: 'GGdate',
  props: {
    value: {
    },
    fmt: {
      type: String,
      default () {
        return '{y}-{m}-{d}'
      }
    }
  },
  computed: {
    val () {
      if (this.value) {
        return parseTime(this.value, this.fmt)
      }
    }
  },
  mounted () {
  }
}
</script>
<style scoped>

</style>
