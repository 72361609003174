<template>
  <div class="footer-module">
    <!-- <div> -->
    <div class="service-box">
      <div class="w f-list-infro clearfix">
        <ul>
          <li :key="i" v-for="(item,i) in fList">
            <img :src="item.img" alt />
            <div>
              <h2 class="f14 m-t-10">{{item.title}}</h2>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="service-box2 w clearfix">
      <!-- <el-button   >点我跳转关于云纱</el-button> -->
      <!-- <router-link :to="{path:'/aboutCloudYarn'}">点我跳转关于云纱</router-link> -->
      <div class="service-box2-box1">
        <ul style="">
          <li :key="parent.code" v-for="(parent) in helpList">
            <h6>{{parent.name}}</h6>
            <template v-for="(child, i) in (parent.children || [])">
              <!-- ,parent.name,child.name -->
              <a :key="child.code" @click="toDetail(child,parent.name,child.name)" href="javascript:;"
                v-if="i < 6">{{child.name}}</a>
            </template>
          </li>
        </ul>
      </div>
      <div class="service-box2-box2">
        <div class="f14" style="margin-left: 9%;margin-top: 11px;">
          <p style="margin-bottom: 15px;"><span style="color: #313233;">客服热线: </span><span
              style="color:#2F6ED5;">0755-2660 3402</span></p>
          <p style="margin-bottom: 15px;"><span style="color: #313233;">公司邮箱: </span><span
              style="color:#2F6ED5;">yssk.group@cloudyarn.cn</span></p>
          <p><span style="color: #313233;">公司地址: </span><span>深圳市南山区深圳湾科技生态园10A栋919室</span></p>
        </div>
        <div class="qr-code">
          <div class="left">
            <img src="@/assets/img/footer/云纱数科公众号-1.png" alt="">
            <p>云纱公众号</p>
          </div>
          <div class="left">
            <img src="@/assets/img/footer/云纱数科APP-1.png" alt="">
            <!-- <img v-if="wechaturl == 'https://www.cloudyarn.cn/'" src="@/assets/img/download-mall.png" alt="">
            <img v-else src="@/assets/img/download-uat.png" alt=""> -->
            <p>云纱App</p>
          </div>
        </div>
        <!-- <div class="left">
          <p>云纱公众号</p>
          <img src="@/assets/img/home/wxno.png" alt="">
        </div>
        <div class="left">
          <p>云纱App</p>
          <img v-if="wechaturl == 'https://www.cloudyarn.cn/'" src="@/assets/img/download-mall.png" alt="">
          <img v-else src="@/assets/img/download-uat.png" alt="">
        </div> -->
      </div>
      <div class="service-box2-box3">
        <div class="box3-l">
          <p style="color: #999999;font-weight: bold;margin-bottom: 20px;">友情链接</p>
          <p class="box3-l-b">
            <el-link :underline="false" href="https://www.cntac.org.cn" target="_blank">中国纺织工业联合会</el-link>
          </p>
          <p class="box3-l-b">
            <el-link :underline="false" href="http://ccta.org.cn" target="_blank">中国棉纺织行业协会</el-link>
          </p>
          <p class="box3-l-b">
            <el-link :underline="false" href="http://cwta.org.cn" target="_blank">中国毛纺织行业协会</el-link>
          </p>
          <p class="box3-l-b">
            <el-link :underline="false" href="http://www.cblfta.org.cn" target="_blank">中国麻纺织行业协会</el-link>
          </p>
          <p class="box3-l-b">
            <el-link :underline="false" href="http://www.silk-e.org.cn" target="_blank">中国丝绸协会</el-link>
          </p>
          <p class="box3-l-b">
            <el-link :underline="false" href="http://ccfa.com.cn" target="_blank">中国化学纤维工业协会</el-link>
          </p>
          <p class="box3-l-b">
            <el-link :underline="false" href="http://www.cdpa.org.cn" target="_blank">中国印染行业协会</el-link>
          </p>
        </div>
        <div class="box3-r">
          <p class="box3-l-b">
            <el-link :underline="false" href="http://www.ckia.org" target="_blank">中国针织工业协会</el-link>
          </p>
          <p class="box3-l-b">
            <el-link :underline="false" href="http://www.hometex.org.cn" target="_blank">中国家用纺织品行业协会</el-link>
          </p>
          <p class="box3-l-b">
            <el-link :underline="false" href="http://www.cfwainfo.org" target="_blank">中国长丝织造协会</el-link>
          </p>
          <p class="box3-l-b">
            <el-link :underline="false" href="http://cnita.org.cn" target="_blank">中国产业用纺织品行业协会</el-link>
          </p>
          <p class="box3-l-b">
            <el-link :underline="false" href="https://www.ctma.net" target="_blank">中国纺织机械协会</el-link>
          </p>
          <p class="box3-l-b">
            <el-link :underline="false" href="http://www.cnga.org.cn" target="_blank">中国服装协会</el-link>
          </p>
        </div>
        <div>
          <p></p>
        </div>
      </div>
      <!-- <div class="footer-left">
        <ul class="clearfix">
          <li :key="parent.code" v-for="(parent) in helpList">
            <h6>{{parent.name}}</h6>
            <template v-for="(child, i) in (parent.children || [])">
              <a :key="child.code" @click="toDetail(child)" href="javascript:;" v-if="i < 6">{{child.name}}</a>
            </template>
          </li>
        </ul>
      </div>

      <div class="footer-right">
        <div class="f">
          <div class="ser-box">
            <div>
              <p>微信公众号</p>
              <img alt src="../../../assets/img/footer-wx.png" />

            </div>
          </div>
        </div>
      </div> -->
    </div>
    <!-- <div class="information-platform">
      <div @click="asRegards"><a>关于云纱</a></div>
      <div @click="helpCenter"><a>帮助中心</a></div>
      <div>
        <router-link :to="{path:'/help/helpList/10a48f7e04a24992889233dbf598a4ea'}">云纱资讯</router-link>
      </div>
      <div @click="contactUs"><a>联系我们</a>
      </div>
    </div> -->
    <!-- </div> -->
    <div class="service-box3 w">
      <div class="box3-copyright">云纱数科 版权所有  <a href="javascript:" onClick="window.open('https://beian.miit.gov.cn/')">粤ICP备2020105505号-1</a> <a href="javascript:" onClick="window.open('https://tsm.miit.gov.cn/')">ICP证: 粤B2-20210423</a></div>
      <div class="box3-img">
        <img src="../../../assets/img/footer/f-1.png" alt="">
        <img src="../../../assets/img/footer/f-2.png" alt="">
        <img src="../../../assets/img/footer/f-3.png" alt="">
        <img src="../../../assets/img/footer/f-4.png" alt="">
      </div>
    </div>
    <!-- 诚信说明 -->
    <!-- <div class="service-box3">
      <div class="wrap">
        <p class="m-b-10" style="margin: 0">
          <a :href="linkUrl" target="_blank">云纱网</a>
          版权所有
          <a href="https://beian.miit.gov.cn/" target="_blank">粤ICP备2020105505号-1 </a>
          <a target="_blank">ICP证: 粤B2-20210423 </a>
        </p>
        <div class="footer-img">
          <a href="javascript:;">
            <img height="46" src="//a1.gystatic.com/v6/images/footer/foot_img3.jpg" width="124" />
          </a>
          <a href="javascript:;">
            <img height="46" src="//a1.gystatic.com/v6/images/footer/foot_img4.jpg" width="124" />
          </a>
          <a href="javascript:;">
            <img height="46" src="//a1.gystatic.com/v6/images/footer/foot_img5.jpg" width="124" />
          </a>
          <a href="javascript:;">
            <img height="46" src="//a1.gystatic.com/v6/images/footer/foot_img7.jpg" width="124" />
          </a>
        </div>
      </div>
    </div> -->
  </div>
</template>
<script>
  import navigationApi from "../../../api/navigationApi";
  import urlencode from "urlencode";

  export default {
    inject: ["reload"],
    data() {
      return {
        // linkUrl: this.$defaultLinkOrigin + '/',
        linkUrl: this.$defaultLinkOrigin,
        fList: [{
            title: "免费找货",
            img: require("@/assets/img/footer/f-icon1.png"),
          },
          {
            title: "仓库直供",
            img: require("@/assets/img/footer/f-icon2.png"),
          },
          {
            title: "品类齐全",
            img: require("@/assets/img/footer/f-icon3.png"),
          },
          {
            title: "一体化服务",
            img: require("@/assets/img/footer/f-icon4.png"),
          },
          {
            title: "供应链服务",
            img: require("@/assets/img/footer/f-icon5.png"),
          },
        ],
        helpList: [],
        helpLists: [],
        platlink: [],
        helpLista: [],
        wechaturl: "",
        tt: []
      };
    },
    created() {
      setTimeout(() => {
        this.platlink = this.$dictList("SYSTEM_TYPE");
        this.wechaturl = this.platlink[0].label;
      }, 1000);
      this.queryHelpList();
    },
    methods: {
      // asRegards() {
      //   this.$router.push({
      //     path: "/aboutCloudYarn",
      //     query: {
      //       childName: '企业介绍',
      //     },
      //   });
      // },
      contactUs() {
        this.$router.push({
          path: "/aboutCloudYarn",
          query: {
            // childName: '联系我们',
            activeType: 'six'
          },
        });
        location.reload();
      },
      queryHelpList() {
        navigationApi
          .tree({
            classify: "SERVICE_ITEM",
          })
          .then((data) => {
            sessionStorage.setItem("helpMenuList", JSON.stringify(data));
            this.helpLista = data;
            this.helpLists = data.length < 2 ? data : data.splice(0);
            this.helpLists.forEach((item) => {
              if (item.name == "买家指南") {
                this.helpList.push(item);
              }
              if (item.name == "卖家指南") {
                this.helpList.push(item);
              }
              // if (item.name == '协议管理') {
              //   this.helpList.push(item)
              // }
              // if (item.name == '关于云纱') {
              //   this.helpList.push(item)
              // }
            });
          })
          .catch((error) => {
            console.error("查询底部列表信息遇到异常:", error);
          });
      },
      helpCenter() {
        let help = this.helpLists[0].children[0].code
        this.$router.push({
          path: "/helpcenter",
          query: {
            routeId: help
          },
        });
      },
      toDetail({
        resource
      } = {}, tabName, childName = "") {
        if (resource.target && resource.target == "_BLANK") {
          var tempwindow = window.open("_blank");
          tempwindow.location = resource.url;
        } else if (tabName.indexOf("云纱") != -1) {
          this.$router.push({
            path: "/aboutCloudYarn",
            query: {
              routeId: resource.code,
              tabName: tabName,
              childName
            },
          });
        } else if (tabName == "买家指南") {
          this.$router.push({
            path: "/helpcenter",
            query: {
              routeId: resource.code,
              tabName: tabName,
              childName
            },
          });
        } else if (tabName.indexOf("卖家指南") != -1) {
          this.$router.push({
            path: "/helpcenter",
            query: {
              routeId: resource.code,
              tabName: tabName,
              childName
            },
          });
        }
        // else {
        //   this.$router.push({
        //     path: "/helpcenter",
        //     query: {
        //       routeId: resource.code,
        //       tabName: tabName,
        //     },
        //   });
        // }
      },
    },
  };

</script>
<style lang="scss" scoped>
  .footer-module {
    background-color: #fbfbfb;

    .service-box {
      min-width: 1200px;
      height: 130px;
      background-color: #fbfbfb;

      // padding: 25px 0 0 0;
      .wrap {
        border: solid 1px #c8a977;
        font-size: 14px;
        border-radius: 6px;
        width: 1200px;
        overflow: hidden;
        color: #c8a977;
        margin: 0 auto;

        li {
          float: left;
          text-align: center;
          width: 33.33%;
          padding: 10px 0;
        }

        em {
          font-weight: bold;
        }
      }

      .f-list-infro {
        li {
          height: 130px;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 20%;
          float: left;
          // padding-bottom: 20px;
          font-size: 14px;

          img {
            margin-right: 10px;
            width: 40px;
            height: 40px;
            margin-top: 10px;
          }

          h2 {
            font-size: 18px;
            color: #384661;
          }
        }
      }
    }

    .service-box2 {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #fbfbfb;
      position: relative;
      border-top: 1px solid #efefef;
      border-bottom: 1px solid #efefef;
      width: 1200px;
      min-width: 1200px;
      height: 358px;

      &-box1 {
        width: 400px;
        height: 277px;
        border-right: 1px solid #efefef;
        color: red;
        border-radius: 15px 0 0 15px;

        ul {
          display: flex;
          justify-content: space-around;
          margin-top: -30px;
        }
      }

      &-box2 {
        width: 400px;
        height: 277px;
        border-radius: 15px;
        margin-top: -19px;

        .qr-code {
          display: flex;
          justify-content: center;
          margin-top: 38px;

          .left {
            width: 43%;
            color: #898989;
            text-align: center;

            img {
              width: 102px;
              height: 100px;
            }
          }
        }
      }

      &-box3 {
        width: 400px;
        height: 277px;
        // padding: 20px;
        border-left: 1px solid #efefef;
        display: flex;
        padding-left: 28px;

        // background: rgba(47, 49, 53, 0.05);
        // border-radius: 0 15px 15px 0;
        // border-left: 1px solid rgba(255, 255, 255, 0.08);
        // color: #fff;
        .box3-l {
          margin-top: -8px;

          .box3-l-b {
            margin-bottom: 15px;
          }
        }

        .box3-r {
          margin-top: 30px;
          margin-left: 25px;

          .box3-l-b {
            margin-bottom: 15px;
          }
        }
      }

      ul {
        h6 {
          color: #999999;
          font-size: 14px;
          font-weight: bold;
          display: block;
          margin-bottom: 20px;
        }

        li {
          // width: 243px;
          height: auto;
          margin-bottom: 20px;
          float: left;
          margin-top: 30px;
        }

        a {
          display: block;
          color: #333333;
          font-size: 14px;
          // line-height: 2;
          margin-bottom: 15px;
          cursor: pointer;
        }
      }

      .line {
        width: 2%;
        display: flex;
        align-items: center;

        span {
          background: #e9e9e9;
          width: 1px;
          height: 100px;
          display: inline-block;
        }
      }

      .footer-left {
        /*width: 70%;*/
        float: left;
      }

      .footer-right {
        width: 30%;
        position: absolute;
        top: 0;
        right: 0;

        .ser-box {
          width: 98%;
          position: relative;
          min-height: 190px;
          display: flex;
          justify-content: flex-end;

          img {
            width: 100px;
          }

          div:first-child {
            margin-right: 25px;
          }

          >img {
            display: inline-block;
          }

          p {
            text-align: center;
          }
        }

        .phone {
          font-weight: bold;
          display: block;
          margin-bottom: 8px;
        }

        .ask {
          width: 150px;
          padding: 8px 20px;
          border-radius: 1px;
        }

        .service-time {
          margin-bottom: 5px;
        }
      }
    }

    .service-box2 ul li.ewm {
      width: 340px;
      border: none;
      padding-left: 50px;
    }

    .service-box2 ul .ewm-l {
      width: 160px;
      float: left;
      display: inline;

      span {
        font-size: 16px;
        color: #fff;
        font-weight: bold;
        margin-bottom: 10px;
      }

      a {
        width: 130px;
        height: 28px;
        text-align: center;
        line-height: 28px;
        border: 1px solid $color-primary-base;
        background-color: $color-primary-base;
        color: #fff;
        margin: 10px 0;
      }
    }

    .spe-bg {
      height: 35px;
      background: $color-primary-base;
    }

    .information-platform {
      height: 81px;
      color: #2A3342;
      display: flex;
      align-items: center;
      justify-content: center;

      div {
        margin: 0px 20px 0px 20px;
      }
    }

    .service-box3 {
      height: 116px;
      background-color: #F5F5F5;
      text-align: center;
      width: 100%;

      .box3-copyright {
        padding-top: 22px;
        color: #666666;
      }

      .box3-img {
        margin-top: 13px;

        img {
          margin-right: 19px;
          height: 46px;
          width: 126px;
        }
      }

      // .wrap {
      //   width: 1200px;
      //   margin: 0 auto;
      //   min-width: 1200px;
      //   padding-top: 15px;
      //   position: relative;

      //   /*border-top: 1px solid #e7e7e7;*/
      //   .footer-img {
      //     text-align: center;
      //     position: absolute;
      //     right: 0;
      //     top: 16px;
      //   }

      //   .footer-img img {
      //     width: 50px;
      //     height: auto;
      //   }

      //   p {
      //     font-size: 13px;
      //     line-height: 24px;
      //     color: #999;
      //   }

      //   a {
      //     padding: 0 10px;
      //     color: #999;
      //   }
      // }
    }

    .service-box2-box1 a:hover {
      color: #2f6ed5;
    }
  }

</style>
<style scoped>
  .el-link.el-link--default {
    color: #333333;
  }

  /deep/.el-link.el-link--default:hover {
    color: #2F6ED5;
  }

</style>
