import text from './text'
import money from './money'
import weight from './weight'
import date from './date'
import datetime from './datetime'
import attrSelect from './attr-select'
import PayStatus from '@/components/gg/pay-status.vue'

const component = {
  install: function (Vue) {
    Vue.component('gg-text', text)
    Vue.component('gg-money', money)
    Vue.component('gg-weight', weight)
    Vue.component('gg-date', date)
    Vue.component('gg-datetime', datetime)
    Vue.component('gg-attr-select', attrSelect)
    Vue.component('gg-pay-status', PayStatus)
  }
}
// 导出模块
export default component
