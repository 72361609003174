export default {

  /*
    * 判断obj是否为一个整数
    */
  isInteger (obj) {
    return Math.floor(obj) === obj
  },

  /*
  * 将一个浮点数转成整数，返回整数和倍数。如 3.14 >> 314，倍数是 100
  * @param floatNum {number} 小数
  * @return {object}
  *   {times:100, num: 314}
  */
  toInteger (floatNum) {
    var ret = { times: 1, num: 0 }
    if (this.isInteger(floatNum)) {
      ret.num = floatNum
      return ret
    }
    var strfi = floatNum + ''
    var dotPos = strfi.indexOf('.')
    var len = strfi.substr(dotPos + 1).length
    var times = Math.pow(10, len)
    var intNum = Number(floatNum.toString().replace('.', ''))
    ret.times = times
    ret.num = intNum
    return ret
  },

  /*
    * 核心方法，实现加减乘除运算，确保不丢失精度
    * 思路：把小数放大为整数（乘），进行算术运算，再缩小为小数（除）
    *
    * @param a {number} 运算数1
    * @param b {number} 运算数2
    * @param digits {number} 精度，保留的小数点数，比如 2, 即保留为两位小数
    * @param op {string} 运算类型，有加减乘除（add/subtract/multiply/divide）
    *
    */
  operation (a, b, digits, op) {
    var o1 = this.toInteger(a)
    var o2 = this.toInteger(b)
    var n1 = o1.num
    var n2 = o2.num
    var t1 = o1.times
    var t2 = o2.times
    var max = t1 > t2 ? t1 : t2
    var result = null
    switch (op) {
      case 'add':
        if (t1 === t2) { // 两个小数位数相同
          result = n1 + n2
        } else if (t1 > t2) { // o1 小数位 大于 o2
          result = n1 + n2 * (t1 / t2)
        } else { // o1 小数位 小于 o2
          result = n1 * (t2 / t1) + n2
        }
        return this.toFixed(result / max, digits)
      case 'subtract':
        if (t1 === t2) {
          result = n1 - n2
        } else if (t1 > t2) {
          result = n1 - n2 * (t1 / t2)
        } else {
          result = n1 * (t2 / t1) - n2
        }
        return this.toFixed(result / max, digits)
      case 'multiply':
        result = (n1 * n2) / (t1 * t2)
        return this.toFixed(result, digits)
      case 'divide':
        result = (n1 / n2) * (t2 / t1)
        return this.toFixed(result, digits)
    }
  },

  // 加减乘除的四个接口
  add (a, b, digits) {
    return this.operation(Number.parseFloat(a || 0), Number.parseFloat(b || 0), digits, 'add')
  },
  subtract (a, b, digits) {
    return this.operation(Number.parseFloat(a || 0), Number.parseFloat(b || 0), digits, 'subtract')
  },
  multiply (a, b, digits) {
    return this.operation(Number.parseFloat(a || 0), Number.parseFloat(b || 0), digits, 'multiply')
  },
  divide (a, b, digits) {
    return this.operation(Number.parseFloat(a || 0), Number.parseFloat(b || 0), digits, 'divide')
  },
  toFixed (num, s) {
    const times = Math.pow(10, s)
    let des = num * times + 0.5
    des = parseInt(des, 10) / times
    return des + ''
  }

}
