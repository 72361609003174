// 买方
export default [
  // 订单列表
  {
    path: '/center/buyer/orderlist',
    component: () => import('@views/center/buyer/orderlist')
  },
  // 合同
  {
    path: '/center/buyer/procurementContractList',
    component: () => import('@/views/center/buyer/procurementContractList.vue')
  },
  // 合同进入费用列表
  {
    path: '/center/buyer/paymentBill',
    component: () => import('@/views/center/buyer/payment-bill.vue')
  },
  // 合同明细
  {
    path: '/center/buyer/contractDetail',
    component: () => import('@/views/center/buyer/contractDetail.vue')
  },
  // 订单明细
  {
    path: '/center/buyer/detail',
    component: () => import('@/views/center/buyer/detail.vue')
  },
  // 发票
  {
    path: '/center/buyer/invoiceList',
    component: () => import('@/views/center/buyer/invoiceList.vue')
  },
  // 提货单
  {
    path: '/center/buyer/receiveGoodsBill',
    component: () => import('@/views/center/buyer/receiveGoods-bill.vue')
  },
  // 过户单
  {
    path: '/center/buyer/transferGoodsBill',
    component: () => import('@/views/center/buyer/transferGoods-bill.vue')
  },
  // 付款单
  {
    path: '/center/buyer/contractBillPayList',
    component: () => import('@/views/center/buyer/billpay/list.vue')
  },
  // 发货单
  {
    path: '/center/buyer/sendGoodsBill',
    component: () => import('@/views/center/buyer/sendGoods-bill.vue')
  },
  // 定向合同列表
  {
    path: '/center/buyer/reverse/list',
    component: () => import('@/views/center/buyer/reverse/list.vue')
  },
  // 定向合同详情
  {
    path: '/center/buyer/reverse/detail/:id',
    component: () => import('@/views/center/buyer/reverse/detail.vue')
  }
]
