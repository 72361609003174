<template>
  <span v-bind="$attrs" :class="cls">{{label}}<slot/></span>
</template>
<script>
export default {
  name: 'GGtag',
  props: ['value', 'type'],
  computed: {
    label() {
      if (this.type && this.type.label) {
        return this.type.label(this.value)
      } else {
        return this.value
      }
    },
    cls() {
      return this.type && this.type.label ? 'el-link--' + this.type.key(this.value) : ''
    }
  },
  mounted() {
  }
}
</script>
<style scoped>
</style>
