// 资金管理中心
export default [
// 资金账户
  {
    path: '/center/fund/memberfund',
    component: () => import('@/views/center/fund/memberfund.vue')
  },
  // 银行回单记录
  {
    path: '/center/fund/bankreceiptlist',
    component: () => import('@/views/center/fund/bankreceiptlist.vue')
  },
  // 会员充值
  {
    path: '/center/fund/infund',
    component: () => import('@/views/center/fund/infund.vue')
  },
  // 会员提现
  {
    path: '/center/fund/outfund',
    component: () => import('@/views/center/fund/outfund.vue')
  },
  // 商企付账户说明
  {
    path: '/center/fund/tencentsm',
    component: () => import('@/views/center/fund/tencentsm.vue')
  },
  // 商企付账户开通
  {
    path: '/center/fund/tencentfund',
    component: () => import('@/views/center/fund/tencentfund.vue')
  },
  // 开通商企付
  {
    path: '/center/fund/tencentpay',
    component: () => import('@/views/center/fund/tencentpay.vue')
  },
  {
    path: '/center/fund/tencentpayMoney',
    component: () => import('@/views/center/fund/tencentpayMoney.vue')
  },
  {
    path: '/center/fund/icbc/account',
    component: () => import('@/views/center/fund/icbc/account.vue')
  },
  {
    path: '/center/fund/icbc/files',
    component: () => import('@/views/center/fund/icbc/FilesAdd.vue')
  },
  {
    path: '/center/fund/icbc/accountmanage',
    component: () => import('@/views/center/fund/icbc/accountmanage.vue')
  },
  {
    path: '/center/fund/icbc/flow',
    component: () => import('@/views/center/fund/icbc/flow.vue')
  },
  {
    path: '/center/fund/icbc/moveapply',
    component: () => import('@/views/center/fund/icbc/moveapply.vue')
  },
  {
    path: '/center/fund/icbc/movehistory',
    component: () => import('@/views/center/fund/icbc/movehistory.vue')
  },
  // 会员充值
  {
    path: '/center/fund/icbc/infund',
    component: () => import('@/views/center/fund/icbc/infund.vue')
  },
  // 会员提现
  {
    path: '/center/fund/icbc/outfund',
    component: () => import('@/views/center/fund/icbc/outfund.vue')
  }

]
