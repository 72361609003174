<template>

  <el-select
    v-bind="$attrs"
    v-model="sgoodsid"
    filterable
    placeholder="请先选择规格属性"
    @change="onchange">
    <el-option
      v-for="item in options"
      :key="item.value"
      :label="item.label"
      :value="item.value">
    </el-option>
  </el-select>
</template>

<script>

  export default {
    name: 'GGgoods',
    props: ['commodity', 'value'],
    data() {
      return {
        sgoodsid: '',
        options: [],
        loading: false
      }
    },
    mounted() {

    },
    watch: {
      value(val) {
        this.sgoodsid = val
      },
      'commodity.id'(val) {
        this.remoteMethod('')
      }
    },
    methods: {
      onchange(val) {
        this.$emit('input', val)
      },
      async remoteMethod(query) {
        if (this.commodity.id) {
          this.$store.dispatch('myloading', true)
          const rs = await this.$post('/sp/goods/list.json', { limit: 10000, search_eq_scommodityid: this.commodity.id })
          this.options = rs.data.content.map(x => {
            let str = ''
            for (let i = 0; i < x.attrVo.list.length; i++) {
              const y = x.attrVo.list[i]
              if (i > 0) { str += '/' }
              str += y.value
            }
            return { label: `${str} (${x.scode})`, value: x.id, list: x.attrVo.list, code: x.scode }
          })
        } else {
          this.options = []
        }
        this.getList()
        this.$emit('input', this.sgoodsid)
      },
      getList() {
        let tmp = null
        for (const x of this.options) {
          if (x.value == this.sgoodsid) {
            tmp = x
            break
          }
        }
        if (tmp) {
          this.sgoodsid = tmp.value
          return tmp.list
        }
        return null
      },
      getGoods() {
        let tmp = null
        for (const x of this.options) {
          if (x.value == this.sgoodsid) {
            tmp = x
            break
          }
        }
        if (tmp) {
          return tmp
        }
        return null
      }
    }
  }
</script>
<style scoped>

</style>
