// 融资质押管理中心
export default [
// 仓单管理
  {
    path: '/center/pledge/indenturelist',
    component: () => import('@/views/center/pledge/indentureList')
  },
  {
    path: '/center/pledge/indenturedetail',
    component: () => import('@/views/center/pledge/indentureDetail')
  },
  //质押申请
  {
    path: '/center/pledge/pledgeadd',
    component: () => import('@/views/center/pledge/pledgeAdd')
  },
  {
    path: '/center/pledge/pledgelist',
    component: () => import('@/views/center/pledge/pledgeList')
  }
]
