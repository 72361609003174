<template>
  <div>
    <div class="header-bg" v-if="$route.path == '/aboutCloudYarn' ? false : true">
      <header :style="{'background':isBgGrey ? '#f6f6f6':'#fff'}" class="header-module">
        <!-- 顶部导航 -->
        <div :class="['header-box', 'w', isCenter?'center':'']">
          <ul class="header-left">
            <li class="header-item">
              <a @click="to('/')" class="right10" href="javascript:">欢迎来到云纱网</a>
              <a @click="to('/center/home')" style="cursor: pointer;" v-if="loginFlag">您好，{{LoginUser.name}},{{LoginUser.mobile}},{{LoginUser.detail.scompanyname}}</a>
              <a @click="to('/login')" class="login-name" href="javascript:" v-if="!loginFlag">您好，请登录</a>
              <a @click="to('/register')" href="javascript:" v-if="!loginFlag">免费注册</a>
            </li>
          </ul>
          <ul class="header-right">
            <li class="header-item 0" v-if="loginFlag">
<!--              <a @click="to('/center/member/messageList')" class="icon-text" href="javascript:;">-->
              <a @click="showMessageListFn()" class="icon-text" href="javascript:">
                <span>
                  消息
                  <i>（{{$store.getters.getReadMessage}}）</i>
                </span>
              </a>
            </li>
            <li class="shu" v-if="loginFlag"></li>
            <li class="header-item 0" v-if="loginFlag">
              <a @click="to('/shopping')" class="icon-text" href="javascript:">
                <span>
                  购物车
                  <i>（{{ShopCar.length}}）</i>
                </span>
              </a>
            </li>
            <li class="shu" v-if="loginFlag"></li>
            <li class="header-item">
              <a @click="toHelpCenter('/helpcenter')" href="javascript:">帮助中心</a>
            </li>
            <li class="shu"></li>
            <li class="header-item 0">
              <a @click="to('/center/home')" href="javascript:">会员中心</a>
            </li>
            <li class="shu"></li>
            <li class="header-item 0">
              <a @click="to('/help/helpList/10a48f7e04a24992889233dbf598a4ea')" href="javascript:">云纱资讯</a>
            </li>
            <li class="shu"></li>
            <li class="header-item 0">
              <a @click="to('/aboutCloudYarn')" href="javascript:">关于云纱</a>
            </li>
<!--            <li class="header-item 0 android">-->
<!--              Android下载 <img class="more" src="@/assets/img/down.png" />-->
<!--              <ul>-->
<!--                <li>-->
<!--                  <img src="@/assets/img/andriodapp.png" style="width: 90px;height: auto" />-->
<!--                </li>-->
<!--              </ul>-->
<!--            </li>-->
<!--            <li class="shu"></li>-->
            <li class="shu"></li>
            <li class="header-item 0">
              云纱App <img class="more" src="@/assets/img/down.png" />
              <ul>
                <li>
                  <img style="width: 90px;height: auto" v-if="wechaturl == 'https://www.cloudyarn.cn/'" src="@/assets/img/download-mall.png" alt="">
                  <img style="width: 90px;height: auto" v-else src="@/assets/img/download-uat.png" alt="">
                </li>
              </ul>
            </li>

            <li class="shu" v-if="loginFlag"></li>
            <li class="header-item 0" v-if="loginFlag">
              <a @click="logOut" class="icon-text" href="javascript:">
                <span>退出</span>
              </a>
            </li>
          </ul>
        </div>
      </header>

      <!--  logo和搜索 -->
      <div v-if="!setHeader()">
        <div class="top-tool-bg" v-if="!setCenterHeader()">
          <div class="w logo-box" style="display: flex;position: relative">
            <div @click="to('/')" class="logo-bg" style="cursor: pointer">
              <img :src="logo" alt />
            </div>
            <div class="search-box" style="margin-left: 50px">
              <input @keyup.enter="handleSearch" class="s-input" placeholder="请搜索感兴趣的商品" style="font-size: 13px;" type="text" v-model="search" />
              <input @click="handleSearch" class="s-button" type="button" value="搜索" />
            </div>

            <div class="header-tool-box">
              <!-- 登录状态 -->
<!--              <template v-if="!loginFlag">-->
<!--                <a @click="to('/login')" class="top-btn top-btn-login" href="javascript:;">-->
<!--                  <span>登录 | 注册</span>-->
<!--                </a>-->
<!--              </template>-->
              <a v-if="loginFlag" @click="gocart" class="top-btn" href="javascript:">
                <el-badge :value="ShopCar.length > 0 ? ShopCar.length : ''">
                  <img class="yuan" src="@/assets/img/icon-car.png" alt/> 购物车
                </el-badge>
              </a>
              <a v-if="!loginFlag" @click="gocart" class="top-btn" href="javascript:">
                  <img class="yuan" src="@/assets/img/icon-car.png" alt/> 购物车
              </a>

            </div>
          </div>
<!--          <div class="yunscode">-->
<!--            <img src="@/assets/img/yunshacode.png" style="width: 90px;height: auto" />-->
<!--            <p>下载云纱App</p>-->
          </div>
        </div>
        <!-- 工具导航 -->
        <div class="nav-bg" v-if="!setHeader()">
          <div class="nav-img"></div>
        </div>
        <div v-if="!setHeader()" :class="!setCenterHeader()?'nav-module-else':''" class="nav-module">
          <div :class="['nav-bg', 'w', isCenter?'center':'']">
            <!--会员中心顶部导航栏-->
            <div class="focus-a" v-if="setCenterHeader()" style="padding-left: 20px">
              <div class="center-logo" style="color: #fff;font-size: 16px;text-align: left;cursor: pointer">
                <img src="@/assets/img/logo2221.png" @click="goHome" style="width: 190px;vertical-align: middle;margin-top: 5px;" />
              </div>
            </div>
            <div
              @mouseenter="mouseenterMenu"
              @mouseleave="mouseleaveMenu"
              @mouseover="mouseenterMenu"
              class="focus-a"
              v-if="!setCenterHeader()">
              <div class="fouc-img1">
                <div class="fouc-font">
                  <div class="smenu">
                    <a class="f-a-i" href="javascript:">
                      <i class="iconfont icon-menu f18"></i>产品分类
                    </a>
                    <i class="iconfont icon-arrow-down f18"></i>
                  </div>
                </div>
              </div>

              <div class="dd-inner" v-show="navShow">
                <div class="dd-innerbg">
                  <div class="dd-inner-box">
                    <div :key="parentIndex" class="font-item" v-for="(parentCategory, parentIndex) in goodsCategoryList" style="height:64px">
                      <div class="item fore1">
                        <h3>
                          <a @click="toGoodsListPage(parentCategory.code)" href="javascript:">
                            <img class="icon" src="@/assets/img/home/nav-icon21.png">
                            <img class="iconactive" src="@/assets/img/home/nav-icon20.png">
                            {{parentCategory.name}}
                          </a>
                        </h3>
                        <!-- 此处去掉links显示有bug，先隐藏二级links-->
                        <p v-show="false">
                          <a
                            :key="link.sguid"
                            @click="toGoodsListPage(parentCategory.code, link.sguid)"
                            style="cursor:pointer;"
                            v-for="(link, linkIndex) in (parentCategory.links || []).filter((v, i) => i < 3)"
                          >
                            {{link.sname}}
                            <template v-if="linkIndex !== parentCategory.links.length - 1">|</template>
                          </a>
                        </p>
                        <!-- 显示二级菜单 -->
                        <p>
                          <span :key="linkIndex" v-for="(link, linkIndex) in ( parentCategory.children || [])">
                            <a @click="toGoodsListPage(link.code)" style="cursor:pointer;" v-if="linkIndex <3">
                              {{link.name}}
                              <template v-if="linkIndex !==  parentCategory.children.length - 1">|</template>
                            </a>
                          </span>
                        </p>
                        <!-- <i>&gt;</i> -->
                        <i class="el-icon-arrow-right"></i>
                      </div>
                      <div class="font-item1">
                        <ul class="font-lefty">
                          <dl :key="childIndex" class="fore1 clearfix" v-for="(childCategory, childIndex) in parentCategory.children">
                            <dt>
                              <a @click="toGoodsListPage(childCategory.code)">
                                {{childCategory.name}}
                                <i>&gt;</i>
                              </a>
                            </dt>
                            <dd>
                              <a
                                :key="thirdIndex"
                                @click="toGoodsListPage(childCategory.code, commodity.sguid)"
                                v-for="(commodity, thirdIndex) in childCategory.commodityList"
                              >{{commodity.sname}}</a>
                            </dd>
                          </dl>
                          <div style="height:1px;border-top:solid rgb(214 211 211) 1px;"></div>
                          <!-- <dl class="fore1 clearfix" v-for="(am) in parentCategory.attrMap">
                            <dt>
                                {{am[0]}}
                                <i>&gt;</i>
                            </dt>

                            <dd>
                              <a @click="toGoodsListAttributePage(parentCategory.code,am[0],am[0],amv.label,amv.value)"
                                v-for="(amv) in am[1]"
                              >{{amv.label}}</a>
                            </dd>
                          </dl> -->
                        </ul>

                        <div class="brand-boxs" v-if="JSON.stringify(parentCategory.attrMap) != '[]'" style="width: 200px;height: 480px;background: #F9F9F9;float: right;margin: 20px;padding: 20px;">
                          <!-- {{JSON.stringify(parentCategory.attrMap)}} -->
                          <dl class="fore1 clearfix" v-for="(am) in parentCategory.attrMap">
                            <dt style="font-size: 13px;text-align: center;font-weight: bold;color: #000">
                                {{am[0]}}
                                <!-- <i>&gt;</i> -->
                            </dt>

                            <dd style="line-height: 25px;font-size: 12px;">
                              <p style="margin: 5px 10px;color: #999;" @click="toGoodsListAttributePage(parentCategory.code,am[0],am[0],amv.label,amv.value)"
                                v-for="(amv) in am[1]"
                              >{{amv.label}}</p>
                            </dd>
                          </dl>

                        </div>

                        <div class="brand-box">
                          <div class="brand-box-head">
                            <i class="icon icon-guanli5"></i>
                            <span></span>
                          </div>
                          <div class="brand-box-body"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 工具导航列表 -->
            <div class="focus-b">
              <ul class="nav-box" v-if="setCenterHeader()">
                <li :key="index" v-for="(item,index) in navLists">
                  <a
                    :class="['f16',handleNav(item.path) ? 'active':$store.getters.getRoleValue==item.name?'active':'']"
                    :title="item.name"
                    @click="to(item.path,item.query)"
                    href="javascript:"
                  >{{item.name}}</a>
                </li>
              </ul>
              <ul class="nav-box" v-else>
                <!-- <li :key="index" v-for="(item,index) in navLists" v-if="setCenterHeader()">
                  <a
                    :class="['f16',handleNav(item.path) ? 'active':'']"
                    :title="item.name"
                    @click="to(item.path,item.query)"
                    href="javascript:;"
                  >{{item.name}}</a>
                </li> -->
               <!-- <li v-if="setCenterHeader()">
                 <a  class="active" href="/center/home" >我是买家</a>
                 <a  class="active" href="/center/homes" >我是卖家</a>
               </li> -->
                <li :key="index" v-for="(item,index) in navList" >
                  <a
                    :class="['f16',handleNav(item.path) ? 'active':'']"
                    :title="item.name"
                    @click="goto(item)"
                    style="font-size: 18px;font-weight: 900;"
                    href="javascript:"
                  >{{item.name}}
                    <!-- <img class="icon" v-if="item.name === '云纱'" src="@/assets/img/icon-hot.png" />
                    <img class="icon" v-if="item.name === '云纱云棉'" src="@/assets/img/icon-new.png" /> -->
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import logo from '@/assets/img/logo.png'
import LoginUser from '@/assets/script/LoginUser'
import ShopCar from '../../../assets/script/ShopCar'
import { http } from 'security-view-deal-frogsing'
import { constants } from 'zlib'

export default {
  data () {
    // 以下页面——不显示顶部公共搜索和logo
    this.noneHeaderList = [ '/shop/detail', '/shopping', '/order/comfirmOrder', '/agreement/settled' ]
    this.noneCenterHeaderList = [ '/center' ]
    // 以下页面——顶部工具栏颜色需灰色
    this.topToolGrey = [ '/shopping', '/order/comfirmOrder' ]
    return {
      showBac: '关于云纱',
      shopcarnum: 0,
      search: '',
      noReadMessageCount: 0,
      LoginUser,
      ShopCar,
      logo,
      isCenter: false,
      path: '',
      loginFlag: LoginUser.isLogin() || false, // 是否登录，默认false
      userInfo: LoginUser.getUser(),
      goodsCategoryList: [],
      navList: [
        {
          name: '首页',
          path: '/'
        },
        {
          name: '云纱',
          path: '/goods/list'
        },
        {
          name: '云纱云布',
          path: 'https://cloth.cloudyarn.cn/',
          isJump: true
        },
        {
          name: '云纱云棉',
          path: 'http://cotton.cloudyarn.cn/',
          isJump: true
        },
        // {
        //   name: '特卖专区',
        //   path: '/goods/promotionlist'
        // },
        {
          name: '云纱甄选',
          path: '/goods/commoditySelection'
        },
        // {
        //   name: '实单求购',
        //   path: '/purchase/list'
        // },
        {
          name: '我要买纱',
          path: '/OracleSourcing/list'
        },
        // {
        //   name: '云纱金服',
        //   path: '/finance/finance'
        // },
        {
          name: '智能云仓',
          path: '/warehouse/warehouse'
        },
        // {
        //   name: '云纱资讯',
        //   path: '/help/helpList/10a48f7e04a24992889233dbf598a4ea'
        // },
        // {
        //   name: '关于云纱',
        //   path: '/aboutCloudYarn'
        // }
      ],
      navLists: [
        {
          name: '首页',
          path: '/'
          // query:{
          //   path:0
          // }
        },
        {
          name: '我是卖家',
          path: '/center/homes',
          query: {
            path: 1
          }
        },
        {
          name: '我是买家',
          path: '/center/home',
          query: {
            path: 0
          }
        },
        {
          name: '资金管理',
          path: '/center/fund/memberfund',
          query: {
            path: 2
          }
        },
        {
          name: '云纱云仓',
          path: '/center/seller/memberWarehouse',
          query: {
            path: 3
          }
        },
        {
          name: '账户管理',
          path: '/center/accountDetail',
          query: {
            path: 4
          }
        }

      ],
      navShow: false,
      platlink: [],
      wechaturl: '',
      help: ''
    }
  },
  computed: {
    isBgGrey () {
      const i = this.topToolGrey.findIndex((v) => {
        return this.path.includes(v)
      })
      return i > -1
    }
  },
  mounted () {
    this.path = this.$route.path
    // this.$store.commit('setRoleValue', '我是买家')
  },
  created () {
    if (sessionStorage.getItem('helpMenuList') && JSON.parse(sessionStorage.getItem('helpMenuList'))) {
      const lists = JSON.parse(sessionStorage.getItem('helpMenuList'))
      this.help = lists[0].children[0].code
    }
    setTimeout(() => {
      this.platlink = this.$dictList('SYSTEM_TYPE')
      this.wechaturl = this.platlink[0].label
      // this.navList[1].path = this.platlink[1].label
    }, 2000)
    this.loadGoodsCategoryList()
    if (LoginUser.isLogin()) {
      this.loadShopCar()
      this.readAllMessage()
    }
    if (sessionStorage.getItem('roleValue')) {
      this.$store.commit('setRoleValue', sessionStorage.getItem('roleValue'))
    }
    window.addEventListener('beforeunload', () => {
      sessionStorage.setItem('roleValue', this.$store.getters.getRoleValue)
    })
  },
  methods: {
    windowScrllLitener () {
      var scrollTop = document.body.scrollTop || document.documentElement.scrollTop
      if (scrollTop >= 500) {
        this.navShow = false
      } else {
        this.navShow = true
      }
    },
    windowScrllLiteners () {
      var scrollTop = document.body.scrollTop || document.documentElement.scrollTop
      if (scrollTop < 9999) {
        this.navShow = false
      } else {
        this.navShow = true
      }
    },
    goto (item) {
      if (item.isJump) {
        const ltoken = window.sessionStorage.getItem('authorization')
        if (ltoken !== undefined && ltoken !== null && ltoken !== '') {
          window.open(
            item.path + '?token=' + ltoken.replace('"', '').replace('"', ''),
            '_blank'
          )
        } else {
          window.open(item.path, '_blank')
        }
      } else {
        this.$router.push(item.path)
      }
    },
    showMessageListFn () {
      this.$bus.$emit('showMessageList', true)
    },
    goHome () {
      this.$router.push({
        path: '/'
      })
    },
    readAllMessage () {
      http
        .get('/message/countUnReadMessage', null)
        .then((res) => {
          this.noReadMessageCount = res
          this.$store.commit('setReadMessage', res)
        })
        .catch((error) => {
          this.$message.error('获取未读信息异常:', error)
        })
    },
    // 屏蔽顶部
    setHeader () {
      const i = this.noneHeaderList.findIndex((v) => {
        return this.path.includes(v)
      })
      return i > -1
    },
    // 屏蔽会员中心 搜索
    setCenterHeader () {
      const i = this.noneCenterHeaderList.findIndex((v) => {
        return this.path.includes(v)
      })
      return i > -1
    },

    /**
     * 搜索商品
     */
    handleSearch () {
      if (this.$utils.isEmpty(this.search.trim())) {
        this.$message({
          type: 'warning',
          message: '请输入关键词'
        })
        this.search = ''
        return
      }
      console.debug('本次搜索的关键词为:', this.search)
      this.$router.push(`/goods/list?searchKey=${this.search}`)
    },
    handleNav (path) {
      return this.path === path
    },
    // : async function (path, query = {})
    toHelpCenter () {
      this.$router.push({
        path: '/helpcenter',
        query: {
          routeId: 'be35e9353b754b879c4607bbb058cc6b'
        }
      })
    },
    to: async function (path, query = {}) {
      this.$router.push({
        path,
        query
      })
    },
    gocart () {
      if (!LoginUser.isLogin()) {
        this.$router.push('/login')
      } else {
        this.$router.push('/shopping')
      }
    },
    /**
     * 进入商品列表页面
     *
     * @param categoryId 分类编号
     */
    toGoodsListPage (categoryId, commodityId) {
      let url = `/goods/list?categoryId=${categoryId}`
      if (!this.$utils.isEmpty(commodityId)) {
        url += `&commodityId=${commodityId}`
      }
      this.$router.push(url)
    },

    /**
     * 属性进入商城
     * @param categoryId
     * @param pl
     * @param k
     * @param cl
     * @param v
     */
    toGoodsListAttributePage (categoryId, pl, k, cl, v) {
      let url = `/goods/list?categoryId=${categoryId}`
      if (
        !this.$utils.isEmpty(pl) &&
        !this.$utils.isEmpty(k) &&
        !this.$utils.isEmpty(cl) &&
        !this.$utils.isEmpty(v)
      ) {
        url += '&pl=' + pl + '&k=' + k + '&cl=' + cl + '&v=' + v
      }
      this.$router.push(url)
    },

    // 退出
    logOut () {
      LoginUser.clear()
      sessionStorage.removeItem('isPerfectInfo')
      ShopCar.data = []
      this.$store.commit('setReadMessage', 0)
      this.loginFlag = false
      this.$router.push('/login')
    },
    loadShopCar () {
      http
        .post('/front/cart/countCart', {})
        .then((data) => {
          this.shopcarnum = data
          ShopCar.length = data
        })
        .catch((error) => {
          console.error('查询购物车列表信息遇到异常:', error)
        })
    },
    async loadGoodsCategoryList () {
      let data
      try {
        data = await http.get(
          '/goods/category/alltree',
          {},
          {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
          }
        )
      } catch (e) {
        this.$myTip.error('查询商品分类信息失败:', e)
        return
      }

      data.forEach((p) => {
        const links = []
        const dateMap = new Map()
        if (p.children) {
          // p.children.forEach((c) => {
          //   // 查询品名
          //   http
          //     .get('/goods/commodity/vo/list', { scategoryid: c.code })
          //     .then((list) => {
          //       c.commodityList = list
          //       if (list && list.length > 0) {
          //         links.push(list[0])
          //       }
          //     })
          // })

          // 获取首页显示的属性值
          if (p.resource.sjson) {
            const attrs = JSON.parse(p.resource.sjson)
            if (attrs) {
              attrs.forEach((a) => {
                if (a.indexshow) {
                  let usedict = false
                  if (
                    Reflect.has(a, 'dictType') &&
                    !this.$utils.isEmpty(a.dictType)
                  ) {
                    usedict = true
                  }
                  let defValues = a.defValues
                  if (usedict) {
                    defValues = this.$dictList(a.dictType)
                  }

                  dateMap.set(a.field, defValues)
                }
              })
            }
          }
        }
        p.links = links
        p.attrMap = dateMap
      })
      this.goodsCategoryList = data
    },

    // 菜单显示和隐藏
    mouseenterMenu () {
      if (this.path === '/goods/category') {
        this.navShow = true
      } else {
        this.navShow = true
      }
    },
    mouseleaveMenu () {
      if (this.path === '/goods/category') {
        this.navShow = true
      } else {
        this.navShow = false
      }
    }
  },
  watch: {
    LoginUser: {
      handler () {
        if (LoginUser.isLogin()) {
          this.loadShopCar()
          this.readAllMessage()
        }
        this.loginFlag = LoginUser.isLogin()
      },
      deep: true
    },
    $route (to) {
      this.path = to.path
      if (to.path === '/center/home') {
        this.$store.commit('setRoleValue', '我是买家')
      } else if (to.path === '/center/homes') {
        this.$store.commit('setRoleValue', '我是卖家')
      } else if (to.path === '/center/fund/memberfund') {
        this.$store.commit('setRoleValue', '资金管理')
      } else if (to.path === '/center/accountDetail' || to.path === '/center/info') {
        this.$store.commit('setRoleValue', '账户管理')
      } else if (to.path === '/center/seller/memberWarehouse') {
        this.$store.commit('setRoleValue', '云纱云仓')
      }

      // if (this.path === '/') {
      //   window.addEventListener('scroll', this.windowScrllLitener)
      // } else {
      //   // window.addEventListener('scroll', this.windowScrllLiteners)
      //   window.removeEventListener('scroll', this.windowScrllLitener)
      // }
      if (this.path.indexOf('center') != -1) {
        this.isCenter = true
      } else {
        this.isCenter = false
      }
      // this.navShow = this.path === '/goods/category'
      this.navShow = this.path === '/goods/category'
    }
  }
}
</script>
<style lang="scss">
$nav-height: 50px;
.header-bg {
    position: fixed;
    z-index: 999;
    background-color: #fff;
    width: 100%;
    top: 0;
  .header-module {
    width: 100%;
    height: 36px;
    line-height: 36px;
    background: #fcfcfc;
    border-bottom: 1px solid #f0f0f0;
    .w {
      &.center {
        max-width: 100%;
        padding: 0 15px;
      }
    }
    .header-left {
      float: left;
      font-size: 13px;
      color: #666;
      height: 40px;

      li {
        padding: 0 24px 0 10px;
        position: relative;

        a {
          margin-right: 8px;
          color: #666;
          font-size: 12px;
        }
        a.login-name {
          color: $color-primary-base;
        }
      }
    }

    .header-right {
      display: flex;
      float: right;
      align-items: center;

      li {
        float: left;
        padding: 0 2px;
        font-size: 13px;
        height: 30px;
        padding: 0 22px 0 10px;
        position: relative;
        font-size: 12px;
        color: #666;
        a {
          color: #666;
        }
        ul {
          display: none;
          position: absolute;
          left: -1px;
          padding: 10px 0;
          background: #fff;
          top: 37px;
          z-index: 1;
          width: 109px;
          height: 110px;
        }
      }
      li:hover ul {
        display: block;
      }
      .header-item {
        padding: 0 12px;
        i {
          padding: 2px 0;
          /*background: #2573A4;*/
          /*color: #fff;*/
          color: red;
          font-size: 12px;
          height: 14px;
          display: inline-block;
          vertical-align: middle;
          line-height: 10px;
          margin-left: 0;
          margin-bottom: 3px;
        }
        .more {
          width: 8px;
          height: auto!important;
          display: inline-block;
          vertical-align: middle;
        }
      }
      .header-item:hover .more {
        transform: rotate(180deg);
        -ms-transform: rotate(180deg); /* IE 9 */
        -moz-transform: rotate(180deg); /* Firefox */
        -webkit-transform: rotate(180deg); /* Safari 和 Chrome */
        -o-transform: rotate(180deg); /* Opera */
      }
      .shu {
        width: 1px;
        height: 12px;
        margin-top: 9px;
        background: #ddd;
        padding: 0;
      }
    }
  }
  .top-tool-bg {
    /*background: #f6f6f6;*/
    padding: 12px 0;
  }
  .logo-box {
    margin: auto;
    align-items: center;
    img {
      width: inherit;
      height: 40px;
    }
    .header-tool-box {
      display: flex;
      align-items: center;
      b {
        font-weight: normal;
        margin-left: 20px;
      }
      span {
        display: inline-block;
      }
      a {
        position: relative;
        height: 22px;
        line-height: 22px;
        display: flex;
        align-items: center;
        display: block;
        margin-left: 20px;
      }
      .icon-text {
        padding-right: 30px;
        span {
          position: absolute;
          right: 0;
          top: -1px;
        }
      }
      .top-btn {
        width: 150px;
        height: 40px;
        text-align: center;
        border: 2px solid #dbdbdb;
        line-height: 32px;
        background: #fcfcfc;
        font-size: 14px;
        color: #FFA800;
        margin-left: 30px;
        .yuan{
          width: 26px;
          height: auto;
          margin: 0 1px 3px 0;
          vertical-align: middle;
        }
      }
      .top-btn-login {
        background: #2573A4;
        border: 2px solid #2573A4;
        color: #fff;
      }
    }

    .search-box {
      /*flex: 60%;*/
      display: flex;
      justify-content: center;

      .s-input {
        float: left;
        height: 28px;
        line-height: 28px;
        padding: 4px;
        border-width: 2px 0 2px 2px;
        border-color: #2573a4;
        border-style: solid;
        font-size: 12px;
        font-family: "microsoft yahei";
        width: 480px;
        outline: 0;
        color: rgb(153, 153, 153);
        box-sizing: content-box;
        padding-left: 20px;
        /*border-radius: 100px 0 0 100px;*/
      }

      .s-button {
        float: left;
        width: 68px;
        height: 40px;
        line-height: 38px;
        background: #2573a4;
        border: none;
        color: #fff;
        font-size: 14px;
        cursor: pointer;
        /*border-radius: 0 100px 100px 0;*/
      }
    }
  }

  .nav-bg {
    position: relative;
  }

  // 导航
  .nav-module {
    //height: $nav-height;
    line-height: $nav-height;
    position: relative;
    background: #2573A4;
    // background: $color-primary-base;
    .nav-bg {
      position: relative;
      &.w {
        &.center {
          max-width: 100%;
        }
      }
    }
    .focus-a {
      width: 200px;
      height: $nav-height;
      position: relative;
      z-index: 999;
      line-height: 36px;

      .fouc-img1 {
        background: #f9f9f9;
        height: $nav-height;
        width: 200px;
        padding: 0 15px;
        &:hover {
          background: #f9f9f9;
        }
      }

      .fouc-font {
        font-size: 14px;
        height: $nav-height;
        line-height: $nav-height;
        text-align: center;
        .smenu{
          justify-content: center;
          display: flex;
          align-items: center;
        }
        a {
          color: #666;
          height: $nav-height;
        }

        i {
          margin-right: 5px;
          color: #666;
        }
      }
    }

    .focus-b {
      position: absolute;
      top: 0;
      left: 210px;
      .nav-box {
        height: $nav-height;
        li {
          float: left;
          color: #fff;
          a {
            /*padding: 0 25px;*/
            display: inline-block;
            width: 118px;
            text-align: center;
            height: 42px;
            line-height: 42px;
            color: #fff;
            position: relative;
            .icon {
              position: absolute;
              right: 10px;
              top: 0;
              height: 21px;
            }
          }
          a.active {
            background: #9bb8cf;
            // background:#0068b7;
            font-size: 16px;
            border-radius: 3px;
          }
        }
      }
    }

    .dd-inner {
      height: 520px;
      width: 200px;
      margin-left: 1px;
      margin-left: 0px;
      background: #fff;
      overflow-x: visible;
      box-sizing: border-box;
      a {
        cursor: pointer;
      }
      .dd-innerbg {
        overflow-y: scroll;
        overflow-x: visible;
        height: 520px;
        width: 200px;
        box-sizing: border-box;
        /*火狐隐藏滚动条*/
        scrollbar-width: none;
        // width: -webkit-calc(210px + 17px);
        -ms-overflow-style: none;
      }

      .dd-inner-box {
        height: 520px;
        width: 200px;
        box-sizing: border-box;
      }
    }

    /*谷歌隐藏滚动条*/
    .dd-inner::-webkit-scrollbar {
      display: none;
    }

    .dd-innerbg::-webkit-scrollbar {
      display: none;
    }

    .dd-inner-box::-webkit-scrollbar {
      display: none;
    }

    .dd-inner .font-item {
      /*margin-bottom: 5px;*/
      /*background: #fff;*/
      border-left: 4px solid transparent;
      border-bottom: 1px solid #f0f0f0;
      border-right: 1px solid #f0f0f0;
      transition: all 0.3s ease-in-out;
      cursor: pointer;
      &:hover {
        padding-left: 10px;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.08);
        border-left-color: $color-primary-base;
        border-right-color: transparent;
      }
    }

    .dd-inner {
      .item {
        color: #fff;
        line-height: 22px;
        position: relative;
        padding: 0 10px;
        h3 {
          font-size: 14px;
          font-weight: normal;
          height: 28px;
          line-height: 28px;
          margin-top: 1px;
          a {
            color: #333;
            img {
              width: 20px;
              vertical-align: middle;
              margin-right: 5px;
            }
          }
        }
        p {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          margin-left: 26px;
          a {
            color: #999;
            font-size: 12px;
          }
        }
        .iconactive{
          display: none;
        }
        .icon{
          display: inline-block;
        }
      }
    }

    .dd-inner .item i {
      font: 400 14px/24px consolas;
      position: absolute;
      top: 1px;
      right: 30px;
      width: 4px;
      height: 14px;
      color: #999;
    }

    .dd-inner .item:hover {
      background: #fff;
      h3 a {
        color: #2573a4;
      }
      .iconactive{
        display: inline-block;
      }
      .icon{
        display: none;
      }
    }

    .bb {
      width: 228px;
      height: 2px;
      background: $color-primary-base;
      position: absolute;
      top: 49px;
    }

    .font-item1 {
      position: absolute;
      left: 200px !important;
      top: 0px !important;
      font-size: 14px;
      z-index: 100;
      width: 1000px;
      border: 1px solid #f0f0f0;
      border-left: none;
      background: #fff;
      display: none;
      height: 520px;
      overflow-y: scroll;
      box-sizing: border-box;
      /*box-shadow: 0 0 10px 0 rgba(0,0,0,.08);*/
    }

    .font-item1::-webkit-scrollbar {
      display: none;
    }

    .font-item1 .font-lefty {
      clear: both;
      float: left;
      padding: 6px 3px 56px 20px;
      position: relative;
      overflow-y: scroll;
      height: 457px;
      width: 75%;
      box-sizing: border-box;
      scrollbar-width: none;
      .fore1 {
        width: 100%;
        position: relative;
        display: flex;
        // align-items: center;
        i {
          font: 400 9px/16px consolas;
          position: absolute;
          top: 15px !important;
          margin-left: 5px;
        }
      }
      .fore1 dt {
        min-width: 150px;
        padding: 5px 30px 0 0;
        float: right;
        position: relative;
        font-weight: bold;
        text-align: right;
        margin-top: -1px !important;
        a {
          font-size: 13px;
        }
      }
      dd {
        width: 660px;
        float: left;
        margin-top: 5px;
        span {
          float: left;
          padding: 0 5px;
          margin: 5px 0;
          height: 16px;
          line-height: 16px;
          border-left: 1px solid #e0e0e0;
          position: relative;
          display: block;
          cursor: pointer;
          &:hover {
            color: $bg-color-hover;
          }
        }
        a {
          display: inline-block;
          float: left;
          padding: 0 10px;
          margin: 8px 0;
          height: 16px;
          line-height: 16px;
          border-left: 1px solid #e0e0e0;
          font-size: 12px;
          color: #999;
        }
      }
    }

    .font-item1 .font-lefty::-webkit-scrollbar {
      display: none;
    }

    .font-item1 .font-lefty dd span ul {
      /*display: none;*/
      position: absolute;
      left: 100%;
      top: 20px;
      background: #fff;
      width: 150px;
      z-index: 9999;
      box-shadow: 0 0 16px 0 hsla(0, 0%, 77%, 0.5);
      li {
        line-height: 2.2;
        border-bottom: 1px dashed #f5f5f5;
        padding: 3px 10px;
        a {
          color: #333;
          font-size: 12px;
        }
        a:hover {
          color: $bg-color-hover;
        }
      }
      li:first-child {
        border: none;
        font-size: 14px;
        margin-top: 10px;
      }
      li:first-child a {
        color: #4a8bed;
      }
    }

    .font-item:hover .font-item1 {
      display: block;
    }
  }
  .nav-module-else {
    background: #fff;
    border-bottom: 2px solid #2573A4;
    .focus-b .nav-box li a {
      color: #333;
      width: 110px;
    }
    .focus-b .nav-box li a.active {
      color: #2573A4;
      background: none;
      font-size: 16px;
    }
    .focus-a .fouc-img1 {
      background: #f9f9f9;
      .icon-arrow-down {
        opacity: 0;
      }
      .icon-menu {
        font-weight: bold;
        margin-right: 5px;
      }
    }
  }
}
.yunscode {
  position: absolute;
  right: calc((100vw - 1200px) / 2 + 50px);
  top: 40px;
  z-index: 10;
  img {
    display: block;
  }
  p {
    text-align: center;
    color: #666;
    font-size: 12px;
    margin-top: -5px;
  }
}
</style>
<style  scoped>
/deep/ .el-dropdown {
  font-weight: 600;
  color: red;
  padding-right: 15px;
  margin-left: 14px;
}
/deep/.el-dropdown-menu__item {
  text-align: center;
}
.el-popper {
  margin-top: 3px !important;
}
</style>
