<template>
  <span class="gg-padding" >{{val}}<slot/></span>
</template>

<script>
  export default {
    name: 'GGWeight',
    props: {
      value: {
      },
      fmt: {
        type: String,
        default() {
          return '#,###.######'
        }
      }
    },
    computed: {
      val() {
        return this.$fmt(this.value, this.fmt)
      }
    },
    mounted() {
    }
  }
</script>
<style scoped>

</style>
