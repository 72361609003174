<template>
  <div>
    <template v-if="preview">
      <div :style="{'background': setBg ? '#fff' : '#f8f8f8'}" class="p-t-15">
        <router-view />
      </div>
    </template>
    <template v-else>
      <!-- 顶部 -->
      <header-layout v-show="!setHeader() "></header-layout>

      <div :style="{'background': setBg ? '#fff' : '#f8f8f8'}" class="p-t-15">
        <router-view />
      </div>
<!--      <backto-top></backto-top>-->
      <compare></compare>
      <compareList></compareList>
      <messageList></messageList>
      <footer-layout v-show="!setFooter() "></footer-layout>
      <div class="right-snav" v-show="setRightnav()">
        <ul>
          <li @click="gocart">
            <el-badge :value="ShopCar.length" class="item">
              <img class="yuan" src="@/assets/img/home/right-icon1.png" alt/>
              <img class="hover" src="@/assets/img/home/right-icon11.png" alt/>
            </el-badge>
          </li>
          <!-- <li v-if="!loginFlag" @click="gocart">
              <img class="yuan" src="@/assets/img/home/right-icon1.png" alt/>
              <img class="hover" src="@/assets/img/home/right-icon11.png" alt/>
          </li> -->
          <li @click="showMessageListFn()">
            <el-badge :value="$store.getters.getReadMessage" class="item">
              <img class="yuan" src="@/assets/img/home/right-icon2.png" alt/>
              <img class="hover" src="@/assets/img/home/right-icon22.png" alt/>
            </el-badge>
          </li>
          <li>
            <img class="yuan" src="@/assets/img/home/right-icon3.png" alt/>
            <img class="hover" src="@/assets/img/home/right-icon33.png" alt/>
            <p>0755-2660 3402</p>
          </li>
          <li>
            <img class="yuan" src="@/assets/img/home/right-icon4.png" alt/>
            <img class="hover" src="@/assets/img/home/right-icon44.png" alt/>
            <p class="code">
              <img src="@/assets/img/home/wxno.png" alt/>
              <span>扫码关注云纱</span>
            </p>
          </li>
          <li v-if="gotop" @click="toTop">
            <img class="yuan" src="@/assets/img/home/right-icon5.png" alt/>
            <img class="hover" src="@/assets/img/home/right-icon55.png" alt/>
          </li>
        </ul>
      </div>
    </template>
  </div>
</template>
<script>
import headerLayout from '@/views/layout/children/header.vue'
import footerLayout from '@/views/layout/children/footer.vue'
import backtoTop from '@/components/backtoTop.vue'
import compare from '@/components/compare.vue'
import compareList from '@/components/compare-list.vue'
import messageList from '@/components/messageList.vue'
import ShopCar from '../../assets/script/ShopCar'
import LoginUser from '@/assets/script/LoginUser'
import { http } from 'security-view-deal-frogsing'

export default {
  components: {
    headerLayout,
    footerLayout,
    backtoTop,
    compare,
    compareList,
    messageList
  },
  data () {
    return {
      path: '',
      preview: false,
      gotop: false,
      LoginUser,
      ShopCar,
      shopcarnum: 0,
      loginFlag: LoginUser.isLogin() || false // 是否登录，默认false
    }
  },
  created () {
    ShopCar.data = []
    this.loginFlag = LoginUser.isLogin()
    // 处理后台管理预览商品详情
    const t = this.$utils.urlParam('t')
    if (!this.$utils.isEmpty(t)) {
      const now = new Date().getTime()
      const limit = 1000 * 60 * 1
      const time = now - t
      this.preview = (time > 0 && time <= limit)
    }
    if (LoginUser.isLogin()) {
      this.loadShopCar()
      this.readAllMessage()
    }
  },
  computed: {
    setBg () {
      const noTopList = [ '/login', '/logistics', '/forgetPassword', '/bind', '/register', '/shopping', '/goods/category', '/shop/detail', '/sign/auth', '/appindex' ]
      const i = noTopList.findIndex(v => {
        return this.path.includes(v)
      })
      return !!((i > -1 || this.path == '/'))
    }
  },
  mounted () {
    this.path = this.$route.path
    // 此处true需要加上，不加滚动事件可能绑定不成功
    window.addEventListener('scroll', this.handleScroll, true)
  },
  watch: {
    $route (to) {
      this.path = to.path
    },
    LoginUser: {
      handler () {
        if (LoginUser.isLogin()) {
          this.loadShopCar()
          this.readAllMessage()
        } else {
          ShopCar.data = []
        }
        this.loginFlag = LoginUser.isLogin()
        if (!this.loginFlag) {
          this.$router.go(0)
        }
      },
      deep: true
    }
  },
  methods: {
    gocart () {
      if (!LoginUser.isLogin()) {
        this.$myTip.warning('请先登录')
      } else {
        this.$router.push('/shopping')
      }
    },
    loadShopCar () {
      http
        .post('/front/cart/countCart', {})
        .then((data) => {
          this.shopcarnum = data
          // ShopCar.data = data
          ShopCar.length = data
        })
        .catch((error) => {
          console.error('查询购物车列表信息遇到异常:', error)
        })
    },
    readAllMessage () {
      http
        .get('/message/countUnReadMessage', null)
        .then((res) => {
          this.noReadMessageCount = res
          this.$store.commit('setReadMessage', res)
        })
        .catch((error) => {
          this.$message.error('获取未读信息异常:', error)
        })
    },
    // 屏蔽顶部
    setHeader () {
      const noHeaderList = [ '/register','/logistics', '/login', '/bind', '/forgetPassword', '/sign/auth', '/appindex', '/download/download', '/agreement/settled', '/agreement/privacy' ]
      return noHeaderList.includes(this.path)
    },
    // 屏蔽footer
    setFooter () {
      const noFooterList = [ '/register','/logistics', '/forgetPassword', '/login', '/sign/auth', '/download/download', '/agreement/settled', '/agreement/privacy' ]
      return noFooterList.includes(this.path)
    },
    // 显示右侧菜单
    setRightnav () {
      const Rightnav = [ '/', '/goods/list', '/purchase/list', '/finance/finance', '/warehouse/warehouse' ]
      return Rightnav.includes(this.path)
    },
    showMessageListFn () {
      if (!LoginUser.isLogin()) {
        this.$myTip.warning('请先登录')
      } else {
        this.$bus.$emit('showMessageList', true)
      }
    },
    to: async function (path, query = {}) {
      this.$router.push({
        path,
        query
      })
    },
    handleScroll () {
      const scrolltop = document.documentElement.scrollTop || document.body.scrollTop
      scrolltop > 30 ? (this.gotop = true) : (this.gotop = false)
    },
    toTop () {
      let top = document.documentElement.scrollTop || document.body.scrollTop
      // 实现滚动效果
      const timeTop = setInterval(() => {
        document.body.scrollTop = document.documentElement.scrollTop = top -= 50
        if (top <= 0) {
          clearInterval(timeTop)
        }
      }, 10)
    }
  }
}
</script>
<style lang="scss">
// .sp-line {
//   margin-bottom: 20px;
//   width: 100%;
// }
  .right-snav{
    position: fixed;
    top: 33%;
    right: 10px;
    background: #fff;
    border: 1px solid #eee;
    width: 64px;
    z-index: 1000;
    ul{
      li{
        height: 64px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid #eee;
        cursor: pointer;
        position: relative;
        .yuan{
          display: block;
          width: 26px;
        }
        .hover{
          display: none;
          width: 26px;
        }
        p{
          position: absolute;
          bottom: 0;
          right: 62px;
          color: #2573A4;
          font-weight: 500;
          height: 64px;
          width: 200px;
          border: 1px solid #eee;
          background:#fff;
          line-height: 64px;
          text-align: center;
          font-size: 20px;
          display: none;
          &.code{
            width: 140px;
            height: auto;
            line-height: inherit;
            img{
              width: 100%;
            }

          }
        }
        &:hover{
          color: #2573A4;
          .yuan{
            display: none;
            width: 26px;
          }
          .hover{
            display: block;
            width: 26px;
          }
          p{
            display: block;
            span{
              display: block;
              text-align: center;
              color: #666;
              font-size: 14px;
              margin-top: -10px;
              padding-bottom: 10px;
            }
          }
        }
      }
    }
  }
</style>
