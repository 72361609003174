// 买方定向订单
export default [
  // 一站式采购
  {
    path: '/center/buyer/sourcing/purchaseList',
    component: () => import('@/views/center/buyer/sourcing/purchaseList.vue')
  },
  // 询价列表
  {
    path: '/center/buyer/Inquiry/InquiryList',
    component: () => import('@/views/center/buyer/Inquiry/InquiryList.vue')
  },
  // 询盘详情
  {
    path: '/center/buyer/Inquiry/detail',
    component: () => import('@/views/center/buyer/Inquiry/detail.vue')
  }
]
