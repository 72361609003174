// 卖方
export default [
  // 合同
  {
    path: '/center/seller/salesContractList',
    component: () => import('@/views/center/seller/salesContractList.vue')
  },
  // 合同明细
  {
    path: '/center/seller/contractDetail',
    component: () => import('@/views/center/seller/contractDetail.vue')
  },
  // 订单明细
  {
    path: '/center/seller/detail',
    component: () => import('@/views/center/seller/detail.vue')
  },
  // 订单列表
  {
    path: '/center/seller/salelist',
    component: () => import('@/views/center/seller/salelist.vue')
  },
  // 合同进入收款单列表
  {
    path: '/center/seller/contractBillPayList',
    component: () => import('@/views/center/seller/contractBillPay-list.vue')
  },
  // 发货计划
  {
    path: '/center/seller/shipmentsplanList',
    component: () => import('@/views/center/seller/salesShipmentsPlanList.vue')
  },
  // 发票
  {
    path: '/center/seller/invoiceList',
    component: () => import('@/views/center/seller/invoiceList.vue')
  },
  // 线上发票
  {
    path: '/center/seller/invoiceInformation/onlineInvoice',
    component: () => import('../views/center/seller/invoiceInformation/onlineInvoice.vue')
  },
  // 商品
  {
    path: '/center/seller/goods/list',
    component: () => import('@/views/center/seller/goods/list.vue')
  },
  // 联盟仓库仓单资源列表
  {
    path: '/center/seller/store/storeList',
    component: () => import('@/views/center/seller/store/storeList.vue')
  },
  // 联盟仓库仓单挂牌资源列表
  {
    path: '/center/seller/store/storeGpList',
    component: () => import('@/views/center/seller/store/storeGpList.vue')
  },
  {
    path: '/center/seller/goods/specialSaleList',
    component: () => import('@/views/center/seller/goods/specialSaleList.vue')
  },
  // 联盟仓库仓单挂牌资源新增
  {
    path: '/center/seller/store/storeGpAdd',
    component: () => import('@/views/center/seller/store/storeGpAdd.vue')
  },
  {
    path: '/center/seller/goods/add',
    component: () => import('@/views/center/seller/goods/add.vue')
  },
  {
    path: '/center/seller/goods/specialAdd',
    component: () => import('@/views/center/seller/goods/specialAdd.vue')
  },
  // 发货单
  {
    path: '/center/seller/sendGoodsBill',
    component: () => import('@/views/center/seller/sendGoodsBill.vue')
  },
  // 提货单
  {
    path: '/center/seller/receiveGoodsBill',
    component: () => import('@/views/center/seller/receiveGoods-bill.vue')
  },
  // 过户单
  {
    path: '/center/seller/transferGoodsBill',
    component: () => import('@/views/center/seller/transferGoods-bill.vue')
  },
  // 仓库维护
  {
    path: '/center/seller/warehouseManager',
    component: () => import('@/views/center/seller/warehouseManager.vue')
  },
  // 会员关联仓库
  {
    path: '/center/seller/memberWarehouse',
    component: () => import('@/views/center/seller/memberWarehouse.vue')
  },
  // 逆向合同列表
  {
    path: '/center/seller/reverse/list',
    component: () => import('@/views/center/seller/reverse/list.vue')
  },
  // 定向合同添加
  {
    path: '/center/seller/reverse/add',
    component: () => import('@/views/center/seller/reverse/add.vue')
  },
  // 定向合同详情
  {
    path: '/center/seller/reverse/detail/:id',
    component: () => import('@/views/center/seller/reverse/detail.vue')
  },
  // 入库通知单列表
  {
    path: '/center/seller/stockinnotice/list',
    component: () => import('@/views/center/seller/stockinnotice/list.vue')
  },
  // 入库通知单添加
  {
    path: '/center/seller/stockinnotice/add',
    component: () => import('@/views/center/seller/stockinnotice/add.vue')
  },
  // 入库通知单详情
  {
    path: '/center/seller/stockinnotice/detail/:id',
    component: () => import('@/views/center/seller/stockinnotice/detail.vue')
  },
  // 自放养鸭备案
  {
    path: '/center/seller/record/duck',
    component: () => import('@/views/center/seller/record/duck.vue')
  },
  // 饲料原料备案
  {
    path: '/center/seller/record/feed',
    component: () => import('@/views/center/seller/record/feed.vue')
  }
]
